/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import URL from "constant/url";

export default function SLA() {
  return (
    <>
      <>
        <section className="padding_top_120 padding_bottom_180 bg_home_banner text-center">
          <div className="container">
            <div className="banner_content_box sub_page_banner">
              <h1>Chất Lượng Dịch Vụ Tốt Nhất</h1>
              <p>
                Chúng tôi đảm bảo dịch vụ của Quý khách đang sử dụng đạt uptime
                từ 99,99% trở lên.
              </p>
              <a href={URL.SLADetail} className="btn_banner">
                Xem thêm
              </a>
              <figure>
                <img src="assets/images/svg/shared_banner_img.svg" alt="" />
              </figure>
            </div>
            <div className="scroll_down_box" data-scroll-nav={1}>
              <div className="scroll_down_text">Scroll Down</div>
              <figure>
                <img src="assets/images/scroll_down_img.png" alt="" />
              </figure>
            </div>
          </div>
        </section>
        <section className="padding_top_65 padding_bottom_65">
          <div className="container">
            <div className="row margin_bottom_50">
              <div className="col-xl-6 col-lg-6 col-md-6 margin_mobile">
                <div className="dedicated_why_choose_box">
                  <figure>
                    <img
                      src="assets/images/svg/dedicated_why_choose_img01.svg"
                      alt=""
                    />
                  </figure>
                  <h4>100% Host Node Uptime</h4>
                  <p>
                    Nền tảng phần cứng lưu trữ các phiên bản máy tính của bạn
                    được thiết kế với mục tiêu chính là ổn định. Đồng thời, nhóm
                    kỹ sư của chúng tôi sử dụng giám sát tích cực để chủ động
                    phát hiện các vấn đề và thực hiện các biện pháp phòng ngừa,
                    giảm thiểu bất kỳ tác động nào khi phần cứng máy chủ bị lỗi
                    có thể gây ra đối với môi trường của bạn.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="dedicated_why_choose_box">
                  <figure>
                    <img
                      src="assets/images/svg/dedicated_why_choose_img02.svg"
                      alt=""
                    />
                  </figure>
                  <h4>100% Network Uptime</h4>
                  <p>
                    Sử dụng kiến trúc dự phòng và định tuyến BGP thông minh,
                    mạng lõi định tuyến một cách thông minh xung quanh hoạt động
                    mạng gián đoạn nhằm nỗ lực cung cấp 100% thời gian hoạt động
                    và kết nối hiệu suất cao trong tất cả các POP trên toàn thế
                    giới của chúng tôi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <section className="padding_top_0 padding_bottom_65">
        <div className="container">
          <div className="section_heading margin_bottom_40 text-center">
            <h2>Cam Kết Đền Bù Phí Dịch Vụ</h2>
          </div>
          <table className="large-only table domain_table stacktable">
            <thead className="bg_230fae">
              <tr className="">
                <th className="text-center">Thời gian uptime hàng tháng</th>
                <th className="">Thời gian ở trạng thái không sẵn sàng</th>
                <th className="">Mức phí dịch vụ đền bù</th>
              </tr>
            </thead>
            <tbody className="domain_table_body">
              <tr>
                <td className="text-center">Từ 99,9% tới 100%</td>
                <td className="default">Tối đa 43 phút</td>
                <td>0%</td>
              </tr>
              <tr>
                <td className="text-center">Từ 99% tới ít hơn 99,9%</td>
                <td className="default">Tối đa 430 phút</td>
                <td>30%</td>
              </tr>
              <tr>
                <td className="text-center">Từ 90% tới ít hơn 99%</td>
                <td className="default">Tối đa 4300 phút</td>
                <td>50%</td>
              </tr>
              <tr>
                <td className="text-center">Ít hơn 90%</td>
                <td className="default">Hơn 4300 phút</td>
                <td>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
