/* eslint-disable jsx-a11y/alt-text */
export default function Agreement() {
  return (
    <>
      <section className="blog_banner bg_edf4ff">
        <div className="container">
          <div className="banner_content_box sub_page_banner">
            <h1>Điều khoản sử dụng</h1>
            <span className="mt-4 mb-0" style={{ fontSize: "18px" }}>
              Bằng cách thông qua chính sách này trước khi khởi tạo dịch vụ.
              Khách hàng đã chấp nhận các Quy định và điều khoản sử dụng dịch vụ
              do chúng tôi cung cấp.
            </span>
          </div>
        </div>
      </section>
      <div style={{ fontSize: "16px" }}>
        <section>
          <div className="container ptb-60" style={{ paddingTop: "20px" }}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="pt-blog-post">
                  <div className="pt-blog-contain">
                    <ol>
                      <li>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Người sử dụng (bên mua) phải tự chịu trách nhiệm và
                            đảm bảo với chúng tôi (bên cung cấp) trong việc sử
                            dụng Máy chủ của mình trên hệ thống vào những mục
                            đích hợp pháp. Đặc biệt trong những trường hợp sau:
                          </span>
                        </p>
                        <ul>
                          <li>
                            <p>
                              <span style={{ fontWeight: 400 }}>
                                Không dùng server vào bất kì mục đích/hình thức
                                nào vi phạm pháp luật Việt Nam, đặc biệt về vấn
                                đề chính trị, tôn giáo, bản quyền... Đồng thời
                                có trách nhiệm kiểm soát và ngăn cấm người khác
                                làm điều đó trên máy chủ của mình.
                              </span>
                            </p>
                          </li>
                          <li style={{ fontWeight: 400 }}>
                            <p>
                              <span style={{ fontWeight: 400 }}>
                                Bất kì loại dữ liệu nào mang tính bất hợp pháp,
                                đe dọa, lừa dối, thù hằn, xuyên tạc, nói xấu,
                                tục tĩu, khiêu dâm, xúc phạm...hay các hình thức
                                bị ngăn cấm khác dưới bất kì cách thức nào.
                              </span>
                            </p>
                          </li>
                          <li style={{ fontWeight: 400 }}>
                            <p>
                              <span style={{ fontWeight: 400 }}>
                                Bất kì loại dữ liệu nào mà cấu thành hoặc khuyến
                                khích các hình thức phạm tội; hoặc các dữ liệu
                                mang tính vi phạm luật sáng chế, nhãn hiệu,
                                quyền thiết kế, bản quyền hay bất kì quyền sỡ
                                hữu trí tuệ hoặc các quyền hạn của bất kì cá
                                nhân nào.
                              </span>
                            </p>
                          </li>
                          <li style={{ fontWeight: 400 }}>
                            <p>
                              <span style={{ fontWeight: 400 }}>
                                Người sử dụng không dùng Máy chủ để gửi SPAM,
                                thư QUẢNG CÁO, BULK MAIL, MASS MAIL, BOMB MAIL,
                                …(bất kể từ đâu) có liên quan đến các web site
                                lưu trữ trong hệ thống máy chủ hoặc mang mục
                                đích phá hoại từ server hay mạng của chúng tôi
                                như HACK, DoS, DDoS, Malwares, Virus... Người sử
                                dụng phải đảm bảo tất cả các thông tin được gửi
                                đi phù hợp với các luật có thể áp dụng (bao gồm
                                luật bảo vệ thông tin) và dưới các hình thức an
                                toàn.
                              </span>
                            </p>
                          </li>
                          <li style={{ fontWeight: 400 }}>
                            Khách hàng tự bảo mật an ninh, an toàn các thông tin
                            liên quan tới tài chính trên máy chủ (Ví bitcoin,
                            Paypal, các tài khoản có giá trị...).{" "}
                            <strong>
                              SMARTSERVER chỉ cung cấp máy chủ và đảm bảo máy
                              chủ hoạt động ổn định. SMARTSERVER không khuyến
                              khích và không chịu bất cứ trách nhiệm nào liên
                              quan tới việc rò rỉ, mất mát tài chính liên quan
                              tới việc máy chủ bị xâm nhập trái phép từ bên thứ
                              3.
                            </strong>
                          </li>
                        </ul>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Người sử dụng không dùng các chương trình có khả
                            năng làm tắc nghẽn hoặc đình trệ hệ thống, như gây
                            cạn kiệt tài nguyên hệ thống, làm quá tải bộ vi xử
                            lý và bộ nhớ (HACK, DoS, DDoS, Malwares, Virus,
                            Mining Coin (
                            <strong>
                              hay còn gọi là đào Bitcoin hoặc các loại tiền mã
                              hóa
                            </strong>
                            ),..)
                          </span>
                        </p>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Người sử dụng được dùng tài nguyên host theo đúng
                            thông số tương ứng gói dịch vụ đã đăng ký. Tuy
                            nhiên, nhằm đảm bảo chất lượng cho máy chủ phục vụ,
                            hệ thống sẽ tự động phát hiện các Máy chủ, Host có
                            dấu hiệu lạm dụng tài nguyên hệ thống quá mức
                            (thường xuyên sử dụng CPU ở mức cao, chiếm dụng băng
                            thông lớn...) và đưa ra các phương án giải quyết phù
                            hợp như: Yêu cầu Khách hàng giảm tải, hệ thống chủ
                            động giới hạn tài nguyên, tạm dừng dịch vụ, hủy dịch
                            vụ...
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Người sử dụng giữ một cách an toàn các thông tin
                            nhận biết, mật khẩu hay những thông tin mật khác
                            liên quan đến tài khoản của mình và lập tức thông
                            báo cho chúng tôi khi phát hiện các hình thức truy
                            cập trái phép bằng tài khoản của mình hoặc các sơ hở
                            về bảo mật, bao gồm việc mất mát, đánh cắp hoặc để
                            lộ các thông tin về mật khẩu và các thông tin bảo
                            mật khác.
                          </span>
                        </p>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Chúng tôi sao lưu dữ liệu hàng tuần, tuy nhiên việc
                            sao lưu này chỉ phục vụ cho mục đích quản lý của
                            chúng tôi. Nếu người sử dụng yêu cầu cung cấp, chúng
                            tôi sẽ gửi những bản sao lưu nhưng chúng tôi không
                            chịu trách nhiệm về những sự cố xảy ra cho các dữ
                            liệu này. Vì vậy, chúng tôi khuyến cáo người sử dụng
                            nên sao lưu dữ liệu thường xuyên về máy tính hoặc
                            trên các thiết bị lưu trữ của mình để tránh những sự
                            cố đáng tiếc xảy ra.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Trong khi dùng các biện pháp có thể và nỗ lực nhằm
                            bảo đảm sự toàn vẹn và an toàn cho server, chúng tôi
                            không chịu trách nhiệm bồi thường dữ liệu của người
                            sử dụng lưu trữ tại máy chủ trong trường hợp hệ
                            thống server bị phá hoại từ những người dùng bất hợp
                            pháp, các hacker hoặc do các sự cố bất khả kháng:
                            thiên tai, hoả hoạn, hệ thống máy chủ hỏng vật lý
                            phần cứng.
                          </span>
                        </p>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Chúng tôi sẽ tạm ngưng dịch vụ mà không cần thông
                            báo trước vì lý do vi phạm từ phía người sử dụng ở
                            các mục đã nêu trên. Trường hợp muốn mở lại, khách
                            hàng phải cam kết không tái diễn vi phạm. Trong
                            trường hợp xấu nhất, chúng tôi sẽ buộc phải cắt dịch
                            vụ vĩnh viễn mà không hoàn trả lại phí.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Trước khi dịch vụ hết hạn,{" "}
                          <strong>
                            hệ thống sẽ liên tục gửi Email và SMS để thông báo
                            tới Khách hàng
                          </strong>
                          . Nếu khách hàng không gia hạn,{" "}
                          <strong>
                            hệ thống sẽ tự động hủy dịch vụ trong vòng 3 ngày và{" "}
                            <span style={{ color: "rgb(255, 0, 0)" }}>
                              không thể khôi phục lại
                            </span>
                          </strong>
                          . Chúng tôi không chịu trách nhiệm trong trường hợp
                          Khách hàng không theo dõi Email/SMS, hoặc cung cấp
                          thông tin không đúng, dẫn đến hệ thống không gửi được
                          thông báo.
                          <span style={{ fontWeight: 400 }}>
                            <strong>
                              <span style={{ color: "rgb(255, 0, 0)" }}>
                                <br />
                              </span>
                            </strong>
                          </span>
                        </p>
                      </li>
                    </ol>
                    <p style={{ textAlign: "center" }}>
                      <span style={{ color: "rgb(0, 0, 255)" }}>
                        <strong>CHÍNH SÁCH THANH TOÁN</strong>
                      </span>
                    </p>
                    <ol>
                      <li>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Chúng tôi cam kết hoàn tiền 100% vào tài khoản trên
                            website của Khách hàng trong vòng 1 tuần khởi tạo
                            dịch vụ,{" "}
                            <strong>nếu như dịch vụ không đúng cam kết</strong>.
                            Cam kết ở đây được hiểu là những thông tin về Máy
                            chủ đã được ghi rõ ràng khi khởi tạo. Cụ thể:
                          </span>
                        </p>
                        <ul>
                          <li>
                            <p>
                              <span style={{ fontWeight: 400 }}>
                                Đối với dịch vụ Máy chủ ảo (VPS) được chia sẻ từ
                                cụm máy chủ vật lý,&nbsp;
                                <strong>
                                  khách hàng có quyền sử dụng tối đa tài nguyên
                                  tới mức được công bố
                                </strong>{" "}
                                (nhưng không vi phạm chính sách). Lấy ví dụ Máy
                                chủ ảo có cấu hình 1 vCPU + 1GB RAM + 25GB SSD +
                                1Gbps Network khi cần khách hàng có thể sử dụng
                                tối đa tài nguyên đã được cung cấp.
                              </span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span style={{ fontWeight: 400 }}>
                                Đối với dịch vụ Máy chủ vật lý (Dedicated).
                                Khách hàng toàn quyền quản trị máy chủ mà mình
                                đã thuê và khách hàng có thể&nbsp; sử dụng tải
                                phần cứng tùy ý vì sẽ không ảnh hưởng tới khách
                                hàng khác.
                              </span>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Sử dụng duy nhất VNĐ cho thanh toán các dịch vụ. Các
                            dịch vụ cung cấp đều là dịch vụ trả trước. Bên bán
                            có quyền từ chối các khoản thanh toán liên quan tới
                            gian lận (sử dụng các tài khoản hack, trộm cắp hoặc
                            thông qua các bug, lỗi của website để thực hiện nạp
                            tiền, sử dụng dịch vụ...) đồng thời sẽ thông báo tới
                            các cơ quan chức năng khi cần thiết.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Các khoản dư của khách hàng trong tài khoản tại
                            website{" "}
                            <a href="https://smartserver.vn">
                              https://smartserver.vn
                            </a>
                            &nbsp;khi không còn nhu cầu sử dụng sẽ được hoàn lại
                            tài khoản khi Khách hàng yêu cầu. Các khoản hoàn lại
                            chỉ tính dựa trên Tài khoản chính. Sau khi tiếp nhận
                            yêu cầu của Khách hàng, bộ phận tài chính sẽ kiểm
                            tra và thực hiện chuyển hoàn trong tối đa 30 ngày
                            làm việc.{" "}
                            <strong>
                              Số dư tối thiểu để yêu cầu hoàn tiền là 50.000 VNĐ
                              (Năm mươi nghìn Đồng), phí chuyển khoản Ngân hàng
                              bên bán sẽ chịu.
                            </strong>
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Nếu cần xuất hóa đơn VAT, quý khách vui lòng liên hệ
                            với nhân viên chăm sóc khách hàng.
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Quý khách hàng vui lòng ghi rõ nội dung thanh toán khi
                          chuyển tiền vào tài khoản của CÔNG TY TNHH CÔNG NGHỆ
                          SMARTSERVER. Nội dung gửi kèm khi chuyển tiền chỉ cần
                          số hóa đơn.
                        </p>
                      </li>
                      <li>
                        <p>
                          Nếu điều kiện thanh toán không cho phép điền nội dung
                          hoặc trong vòng 48 giờ kể từ thời gian thanh toán nếu
                          Quý khách hàng không nhận được email xác nhận, Quý
                          khách hàng vui lòng liên hệ CSKH qua hotline hoặc trực
                          tiếp trên website để được hỗ trợ.
                        </p>
                      </li>
                      <li>
                        <p>
                          Quý khách hàng hoàn toàn chịu trách nhiệm với những
                          trường hợp gián đoạn dịch vụ do giao dịch chuyển tiền
                          có nội dung không đầy đủ hoặc nội dung không chính xác
                          hoặc không có nội dung và không thông báo với CÔNG TY
                          TNHH CÔNG NGHỆ SMARTSERVER.
                        </p>
                      </li>
                      <li>
                        <p>
                          Trường hợp Quý khách hàng thực hiện thanh toán tại
                          Việt Nam thì sẽ phải thanh toán thêm 10% VAT và ngược
                          lại, Quý khách hàng sẽ được miễn thuế nếu thanh toán
                          ngoài Việt Nam.
                        </p>
                      </li>
                      <li>
                        <p>
                          Quý khách hàng chịu các khoản phí giao dịch với ngân
                          hàng hoặc các khoản phí phát sinh do bên thứ ba (nếu
                          có).
                        </p>
                      </li>
                    </ol>
                    <p style={{ textAlign: "center" }}>
                      <span style={{ color: "rgb(0, 0, 255)" }}>
                        <strong>CHÍNH SÁCH BẢO MẬT THÔNG TIN KHÁCH HÀNG</strong>
                      </span>
                    </p>
                    <ol>
                      <li>
                        <p>
                          <strong> Thông tin khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      Thông tin khách hàng gồm có tên, địa chỉ email, số điện
                      thoại, địa chỉ liên hệ và các thông tin cá nhân khác do
                      khách hàng chủ động cung cấp hoặc được SMARTSERVER thu
                      thập bằng các phương thức hợp lệ khác.
                      <br />
                      <br />
                      Thông tin khách hàng không bao gồm dữ liệu về website và
                      các dịch vụ khác của khách hàng đang sử dụng hoặc lưu trữ
                      trên máy chủ cung cấp dịch vụ của SMARTSERVER.
                    </p>
                    <ol start={2}>
                      <li>
                        <p>
                          <strong> Dữ liệu của khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      Dữ liệu của khách hàng gồm có mã nguồn website, cơ sở dữ
                      liệu, cấu hình dịch vụ và các dữ liệu khác do khách hàng
                      cung cấp, tự quản lý và lưu trữ trên các máy chủ cung cấp
                      dịch vụ của SMARTSERVER.
                    </p>
                    <ol start={3}>
                      <li>
                        <p>
                          <strong> Thu thập thông tin khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      Khách hàng có thể liên hệ với SMARTSERVER hoặc truy cập
                      các website trong hệ thống quản lý của SMARTSERVER để phục
                      vụ nhu cầu tìm hiểu thông tin về dịch vụ và có thể lựa
                      chọn không cung cấp các thông tin cá nhân.
                      <br />
                      <br />
                      Khi đăng ký và sử dụng dịch vụ của SMARTSERVER, khách hàng
                      phải cung cấp các thông tin liên hệ đầy đủ và chính xác
                      theo yêu cầu của Quy định sử dụng dịch vụ.
                      <br />
                      <br />
                      Khi khách hàng truy cập vào các website trong hệ thống
                      quản lý của SMARTSERVER, chúng tôi có thể sử dụng một số
                      cơ chế đánh dấu qua trình duyệt (cookies) để hỗ trợ việc
                      sử dụng các tiện ích của website. Khách hàng có thể cấu
                      hình trình duyệt để tùy chỉnh việc bật tắt các cơ chế này.
                      <br />
                      <br />
                      Khi khách hàng truy cập vào các website trong hệ thống
                      quản lý của SMARTSERVER, chúng tôi có thể lưu trữ thông
                      tin về trình duyệt, hệ điều hành và thông tin địa chỉ mạng
                      (IP) của khách hàng cho mục đích thống kê hoặc kiểm soát
                      truy cập. Khách hàng có thể lựa chọn các biện pháp liên hệ
                      khác nếu không muốn SMARTSERVER lưu trữ các thông tin này.
                    </p>
                    <ol start={4}>
                      <li>
                        <p>
                          <strong> Thu thập dữ liệu của khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      SMARTSERVER không thu thập dữ liệu của khách hàng dưới bất
                      kỳ hình thức nào. Dữ liệu của khách hàng phải do khách
                      hàng chủ động cung cấp cho SMARTSERVER hoặc do khách hàng
                      trực tiếp lưu trữ trên các máy chủ cung cấp dịch vụ của
                      SMARTSERVER.
                    </p>
                    <ol start={5}>
                      <li>
                        <p>
                          <strong> Lưu trữ thông tin khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      Khi truy cập các website trong hệ thống quản lý của
                      SMARTSERVER hoặc cung cấp thông tin liên hệ theo yêu cầu
                      của SMARTSERVER, khách hàng hoàn toàn chấp nhận rằng những
                      thông tin liên hệ sẽ được lưu trữ trong hệ thống máy chủ
                      của SMARTSERVER.
                      <br />
                      <br />
                      Các thông tin liên hệ của khách hàng sẽ được lưu trữ cho
                      tới khi khách hàng ngừng sử dụng dịch vụ của SMARTSERVER.
                    </p>
                    <ol start={6}>
                      <li>
                        <p>
                          <strong> Lưu trữ dữ liệu của khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      SMARTSERVER không sao lưu hoặc lưu trữ dữ liệu của khách
                      hàng trừ các hình thức đã được xác định cần thiết để cung
                      cấp dịch vụ và được khách hàng xác nhận.
                      <br />
                      <br />
                      Dữ liệu của khách hàng được lưu trữ cho tới khi khách hàng
                      ngừng sử dụng dịch vụ của SMARTSERVER hoặc khách hàng chủ
                      động yêu cầu xóa / hủy dữ liệu.
                    </p>
                    <ol start={7}>
                      <li>
                        <p>
                          <strong> Sử dụng thông tin khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      SMARTSERVER sử dụng các thông tin do khách hàng chủ động
                      cung cấp hoặc thu thập được qua các hình thức khác dành
                      cho mục đích
                    </p>
                    <ul>
                      <li>
                        <p>
                          Thống kê số lượng người truy cập vào website và mức độ
                          quan tâm tới các loại hình dịch vụ của chúng tôi.
                        </p>
                      </li>
                      <li>
                        <p>
                          Quản lý thông tin xác thực quyền sở hữu tài khoản
                          khách hàng (nếu khách hàng có đăng ký tài khoản với
                          SMARTSERVER) và thực hiện các thao tác chăm sóc khách
                          hàng, gồm có:
                          <br />
                          <br /> &nbsp;&nbsp;&nbsp;&nbsp;- Xử lý đơn đặt hàng và
                          cung cấp dịch vụ đăng ký lưu trữ nội dung website và
                          các dịch vụ khác trong giới hạn kinh doanh của
                          SMARTSERVER.
                          <br />
                          <br /> &nbsp;&nbsp;&nbsp;&nbsp;- Tiếp nhận và phản hồi
                          các thắc mắc, đóng góp ý kiến, yêu cầu hỗ trợ, đề xuất
                          hoặc khiếu nại liên quan tới việc cung cấp các dịch
                          vụ.
                          <br />
                          <br /> &nbsp;&nbsp;&nbsp;&nbsp;- Cung cấp thông tin về
                          các chương trình khuyến mãi, bảng giá, chính sách bán
                          hàng và phục vụ khách hàng.
                        </p>
                      </li>
                    </ul>
                    <p>
                      SMARTSERVER cam kết không thực hiện việc quảng cáo các
                      dịch vụ không do SMARTSERVER trực tiếp cung cấp hoặc gửi
                      thư rác cho các mục đích khác.
                      <br />
                      <br />
                      SMARTSERVER cam kết không cung cấp thông tin liên hệ của
                      khách hàng cho các bên thứ ba, trừ các trường hợp được
                      thông báo với khách hàng như sau:
                    </p>
                    <ul>
                      <li>
                        <p>
                          Việc cung cấp là cần thiết để đảm bảo sử dụng dịch vụ
                          (ví dụ, đăng ký thông tin sở hữu tên miền với VNNIC).
                        </p>
                      </li>
                      <li>
                        <p>
                          Việc cung cấp được thực hiện theo yêu cầu bằng văn bản
                          của các đơn vị hành pháp tại Việt Nam.
                        </p>
                      </li>
                    </ul>
                    <ol start={8}>
                      <li>
                        <p>
                          <strong>
                            {" "}
                            Truy cập và chỉnh sửa dữ liệu của khách hàng
                          </strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      SMARTSERVER cam kết chỉ truy cập hoặc chỉnh sửa dữ liệu
                      của khách hàng khi có sự đồng ý xác nhận của khách hàng và
                      chỉ để thực hiện các thao tác phục vụ cho công việc theo
                      yêu cầu của khách hàng, theo đúng trách nhiệm được giao và
                      theo đúng các hướng dẫn, quy trình kỹ thuật có liên quan
                      của công ty.
                      <br />
                      <br />
                      SMARTSERVER cam kết không sử dụng dữ liệu của khách hàng
                      hoặc cung cấp quyền truy cập vào dữ liệu của khách hàng
                      cho các bên thứ ba vì bất kỳ mục đích gì.
                      <br />
                      <br />
                      SMARTSERVER cam kết thực hiện việc bàn giao hoặc xóa toàn
                      bộ các dữ liệu của khách hàng khi khách hàng có yêu cầu.
                    </p>
                    <ol start={9}>
                      <li>
                        <p>
                          <strong>
                            {" "}
                            Bảo vệ an toàn thông tin khách hàng và dữ liệu của
                            khách hàng
                          </strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      SMARTSERVER cam kết sẽ nỗ lực triển khai các biện pháp bảo
                      vệ an toàn thông tin và dữ liệu của khách hàng trong khả
                      năng cho phép của mình, gồm có:
                    </p>
                    <ul>
                      <li>
                        <p>
                          Sử dụng giao thức truyền tin qua mạng Internet được mã
                          hóa với chứng chỉ số (SSL).
                        </p>
                      </li>
                      <li>
                        <p>
                          Xác thực quyền truy cập hoặc chỉnh sửa thông tin khách
                          hàng bằng các biện pháp khác trong trường hợp không
                          truy cập trực tiếp qua mạng Internet.
                        </p>
                      </li>
                    </ul>
                    <p>
                      SMARTSERVER không chịu trách nhiệm nếu xảy ra các sự cố
                      liên quan tới an toàn thông tin khách hàng hoặc dữ liệu
                      của khách hàng phát sinh từ các nguyên nhân không nằm
                      trong quyền kiểm soát của SMARTSERVER, gồm có nhưng không
                      giới hạn bởi việc khách hàng để lộ mật khẩu hoặc bị tấn
                      công bảo mật thông qua các phương thức khác không liên
                      quan tới dịch vụ của SMARTSERVER.
                    </p>
                    <ol start={10}>
                      <li>
                        <p>
                          <strong> Điều chỉnh thông tin khách hàng</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      Khách hàng có thể chủ động việc bổ sung hoặc thay đổi một
                      phần các thông tin khách hàng dựa trên cơ chế quản lý được
                      hệ thống của SMARTSERVER hỗ trợ, gồm có:
                    </p>
                    <ul>
                      <li>
                        <p>
                          Đăng nhập vào giao diện tài khoản khách hàng tại đường
                          dẫn{" "}
                          <a href="https://manage.smartserver.vn">
                            https://manage.smartserver.vn
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          Liên hệ với bộ phận chăm sóc khách hàng của
                          SMARTSERVER theo Hotline trên trang chủ hoặc địa chỉ
                          đại diện Công ty:
                        </p>
                      </li>
                      <ul>
                        <li>
                          <p>CÔNG TY TNHH CÔNG NGHỆ SMARTSERVER</p>
                        </li>
                        <li>
                          <p>
                            Số 30 ngõ 102 Tô Vĩnh Diện, Phường Khương Trung,
                            Quận Thanh Xuân, Thành phố Hà Nội
                          </p>
                        </li>
                      </ul>
                    </ul>
                    <p>
                      SMARTSERVER sẽ chủ động hoặc thực hiện theo yêu cầu của
                      khách hàng các thao tác bổ sung, thay đổi hoặc xóa dữ liệu
                      thông tin khách hàng.
                      <br />
                      <br />
                      SMARTSERVER có thể từ chối hủy một số thông tin khách hàng
                      nhằm mục đích thống kê hoặc kiểm soát chất lượng. Các
                      thông tin này sẽ được lưu trữ cho tới khi SMARTSERVER
                      ngừng cung cấp các dịch vụ có liên quan.
                    </p>
                    <ol start={11}>
                      <li>
                        <p>
                          <strong> Hệ thống website của SMARTSERVER</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      Hệ thống website của SMARTSERVER là trang web sử dụng tên
                      miền chính https://smartserver.vn
                      <br />
                      <br />
                      Các website của SMARTSERVER có thể bao gồm các đường dẫn
                      liên kết tới các trang web khác nhằm mục đích giới thiệu
                      hoặc bổ sung thông tin. Chúng tôi không chịu trách nhiệm
                      về nội dung và chính sách bảo mật thông tin của các trang
                      web này.
                    </p>
                    <ol start={12}>
                      <li>
                        <p>
                          <strong> Thay đổi chính sách</strong>
                        </p>
                      </li>
                    </ol>
                    <p>
                      Để phù hợp kịp thời với các nhu cầu của SMARTSERVER và sự
                      phản hồi từ khách hàng (nếu có), chúng tôi có thể thay đổi
                      các điều khoản nội dung của chính sách bảo mật thông tin
                      khách hàng này mà không cần thông báo trước.
                      <br />
                      <br />
                      SMARTSERVER sẽ cố gắng thông báo cho khách hàng qua thư
                      điện tử nếu có các điều chỉnh quan trọng có khả năng ảnh
                      hưởng tới quyền lợi của khách hàng.
                      <br />
                      <br />
                      Khi cập nhật nội dung chính sách này, SMARTSERVER sẽ chỉnh
                      sửa lại thời gian trong phần ghi chú “cập nhật lần cuối”
                      trên website.
                    </p>
                    <p style={{ textAlign: "right" }}>
                      <em>
                        <strong>
                          Cập nhật lần cuối ngày 30 tháng 06 năm 2021
                        </strong>
                      </em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
