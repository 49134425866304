/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function DedicatedServerCategory() {
  const navigate = useNavigate();
  return (
    <li className="nav-item dropdown default_header_dd">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Dedicated Server
      </a>
      <div
        className="dropdown-menu default_dd_menu hosting_dd_menu"
        aria-labelledby="navbarDropdown"
        style={{width: '250px'}}
      >
        <div id="accordion" className="row hide_on_tablet">
          <div className="nav-item col-md-12">
            <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
              THEO KHU VỰC
            </span>
            <a
              className="dropdown-item pb-2 d-flex"
              href=""
              onClick={() => {
                navigate(URL.ListDedicatedServerEU);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/european-union.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span style={{ alignSelf: "center" }}>Dediacated Châu Âu</span>
            </a>
            <a
              className="dropdown-item pt-0 d-flex"
              href=""
              onClick={() => {
                navigate(URL.ListDedicatedServerUS);
              }}
            >
              <img
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
                src="assets/flags/united-states.png"
                alt="server"
                width={35}
                height={35}
              ></img>
              <span style={{ alignSelf: "center" }}>Dediacated Mỹ</span>
            </a>
          </div>
        </div>
        <ul className="mobile_navigation">
          <a
            className="dropdown-item pb-2 d-flex"
            style={{ fontSize: "12px" }}
            href=""
            onClick={() => {
              navigate(URL.ListDedicatedServerEU);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/european-union.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>Dediacated Châu Âu</span>
          </a>
          <a
            className="dropdown-item pt-0 d-flex"
            style={{ fontSize: "12px" }}
            href=""
            onClick={() => {
              navigate(URL.ListDedicatedServerUS);
            }}
          >
            <img
              style={{
                alignSelf: "center",
                marginRight: "6px",
              }}
              src="assets/flags/united-states.png"
              alt="server"
              width={35}
              height={35}
            ></img>
            <span style={{ alignSelf: "center" }}>Dediacated Mỹ</span>
          </a>
        </ul>
      </div>
    </li>
  );
}
