import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide() {
  return (
    <section className="padding_bottom_65 padding_top_120 latest_blog_section">
      <div className="container">
        <div className="section_heading margin_bottom_80 text-center">
          <h2 style={{ fontWeight: 700 }}>Danh sách hướng dẫn</h2>
          <p style={{ fontWeight: 500, fontSize: "18px" }}>
            Tổng hợp các hướng dẫn hỗ trợ kĩ thuật và các tips hữu ích.
          </p>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
            <div className="blog_post_box">
              <figure>
                <img
                  src="assets/images/blog_post_img01.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="blog_content_box">
                <h5
                  style={{
                    fontSize: "20px",
                    marginBottom: "8px",
                    fontWeight: 700,
                  }}
                >
                  Hướng dẫn tăng cường bảo mật VPS
                </h5>
                <div className="post_date" style={{ fontSize: "14px" }}>
                  01 Tháng 1, 2019
                </div>
                <p style={{ fontSize: "16px" }}>
                  Bạn đang chưa biết có cách nào để tăng cường bảo mật VPS. Hãy
                  đọc bài viết sau để biết thêm chi tiết!
                </p>
                <a href={URL.Guide1} className="btn_latest_blog_post">
                  Chi tiết
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
            <div className="blog_post_box">
              <figure>
                <img
                  src="assets/images/blog_post_img02.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="blog_content_box">
                <h5
                  style={{
                    fontSize: "20px",
                    marginBottom: "8px",
                    fontWeight: 700,
                  }}
                >
                  Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                </h5>
                <div className="post_date" style={{ fontSize: "14px" }}>
                  30 Tháng 12, 2018
                </div>
                <p style={{ fontSize: "16px" }}>
                  Có 2 cách thông dụng để đổi mật khẩu VPS WS2012 là bằng dòng
                  lệnh hoặc cài đặt trong User Accounts.
                </p>
                <a href={URL.Guide2} className="btn_latest_blog_post">
                  Chi tiết
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4">
            <div className="blog_post_box">
              <figure>
                <img
                  src="assets/images/blog_post_img03.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
              <div className="blog_content_box">
                <h5
                  style={{
                    fontSize: "20px",
                    marginBottom: "8px",
                    fontWeight: 700,
                  }}
                >
                  Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows.
                </h5>
                <div className="post_date" style={{ fontSize: "14px" }}>
                  28 Tháng 12, 2018
                </div>
                <p style={{ fontSize: "16px" }}>
                  Để mở rộng ổ cứng có thể sử dụng powershell hoặc công cụ Disk
                  Management.
                </p>
                <a href={URL.Guide3} className="btn_latest_blog_post">
                  Chi tiết
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
