/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Contact() {
  return (
    <>
      {/*Hero Banner Section*/}
      <section className="padding_top_120 padding_bottom_180 bg_home_banner text-center about_banner_section contact_banner_section">
        <div className="container">
          <div className="banner_content_box sub_page_banner ">
            <h1>Hãy Liên Hệ Với Chúng Tôi</h1>
            <p>
              Thời gian là tiền bạc! <br /> Chúng tôi xem trọng khách hàng và
              thời gian của mọi người, vì vậy, <br /> đội ngũ của chúng tôi luôn
              làm tốt nhất có thể để giúp bạn giải quyết vấn đề nhanh chóng!
            </p>
            <a href="tel:+(84)973884503" className="btn_banner">
              Hỗ trợ 24/7
            </a>
            <figure>
              <img src="assets/images/svg/contact_banner_img.svg" alt="" />
            </figure>
            <div>
              Điện thoại: <a href="tel:+(84)973884503">+ (84)973884503</a>
            </div>
          </div>
          <div className="scroll_down_box" data-scroll-nav={9}>
            <div className="scroll_down_text">Scroll Down</div>
            <figure>
              <img src="assets/images/scroll_down_img.png" alt="" />
            </figure>
          </div>
        </div>
      </section>
      {/*End Slider Section*/}
      {/*Why choose Section*/}
      <section
        className="padding_top_120 padding_bottom_65"
        data-scroll-index={9}
      >
        <div className="container">
          <div className="section_heading margin_bottom_80 text-center">
            <h2>Thông Tin Liên Hệ</h2>
          </div>
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-12"
              style={{ marginBottom: "40px" }}
            >
              <div className="contact_info_box">
                <figure>
                  <img src="assets/images/svg/contact_img02.svg" alt="" />
                </figure>
                <h4>Admin</h4>
                <ul className="contact_us_list">
                  <li>
                    <div>
                      Telegram:{" "}
                      <a href="https://t.me/hoatrinh34">@hoatrinh34</a>
                    </div>
                    <div>
                      Skype: <a href="skype:hoavipbk?chat">hoavipbk</a>
                    </div>
                    <div>
                      Email:{" "}
                      <a href="skype:hoavipbk?chat">admin@smartserver.vn</a>
                    </div>
                    <div>
                      Supporter:{" "}
                      <a href="https://t.me/Httv_Sp01">
                        https://t.me/Httv_Sp01
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="contact_info_box">
                <figure>
                  <img src="assets/images/svg/contact_img01.svg" alt="" />
                </figure>
                <h4>Fanpage</h4>
                <ul className="contact_us_list">
                  <li>
                    <div>
                      <a href="https://www.facebook.com/httvserver">
                        https://www.facebook.com/httvserver
                      </a>
                    </div>
                    <div>
                      <a href="https://mmo4me.com/threads/vps-dedicated-us-ca-eu-vn-chuyen-auto-scan-ptu-offer-render-cpm-chinh-hang-da-dang-4-5-thang.289700/">
                        https://mmo4me.com/threads/vps-dedicated-us-ca-eu-vn-chuyen-auto-scan-ptu-offer-render-cpm-chinh-hang-da-dang-4-5-thang.289700/
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
